<template>
  <div class="evo-privacy-policy-wrapper evo-mobile mx-auto">
    <!-- breadcrumbs -->
    <div class="evo-breadcrumbs-wrapper">
      <v-breadcrumbs :items="breadcrumbs" class="evo-breadcrumbs">
        <template #divider>
          <v-icon>$vuetify.icons.mdiChevronRight</v-icon>
        </template>
      </v-breadcrumbs>
    </div>
    <!-- content -->
    <EvoMarkdown :content="md.default" class="evo-content-wrapper" />
    <!-- Other News -->
    <div class="evo-opensans-regular">
      <div class="evo-other-news-title evo-text-33 text-uppercase">
        {{ $t('news.other_news') }}
      </div>
      <div class="evo-news-wrapper" v-for="(item, index) in items" :key="index">
        <v-divider></v-divider>
        <router-link :to="item.link" class="router-link">
          <div class="evo-news-title evo-text-27" :class="{ active: item.key === id }">
            {{ $t(item.title) }}
          </div>
        </router-link>
        <div class="evo-news-time evo-text-22">{{ item.date }}</div>
      </div>
      <v-divider></v-divider>
    </div>
  </div>
</template>
<script>
import { newsroomDetailMixin } from '@/mixins/newsroom.js';
export default {
  name: 'EvoMNewsroomDetailSection',
  mixins: [newsroomDetailMixin]
};
</script>
<style lang="scss" scoped>
::v-deep(.evo-breadcrumbs) {
  padding-top: 0;
  padding-left: 0;
  display: inline-block;
  .v-breadcrumbs__item {
    color: #459eff;
    font-size: 33px;
    font-family: 'OpenSans-Regular';
  }
}
.evo-privacy-policy-wrapper {
  position: relative;

  .evo-breadcrumbs-wrapper {
    margin: 30px 0 0;
  }

  .evo-content-wrapper {
    margin-bottom: 254px;
  }

  .evo-other-news-title {
    color: #222a41;
    font-weight: bold;
    line-height: 46px;
    margin-bottom: 40px;
  }

  .evo-news-wrapper {
    margin-bottom: 22px;
    .evo-news-title {
      padding-top: 14px;
      color: #222a41;
      font-weight: bold;
      &.active {
        color: #b4b4b4;
      }
    }
    .evo-news-time {
      padding-top: 18px;
      color: #6d7783;
    }
  }
}
</style>
