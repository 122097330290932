<template>
  <div class="evo-privacy-policy-wrapper">
    <!-- breadcrumbs -->
    <div class="mb-8">
      <v-breadcrumbs :items="breadcrumbs" class="evo-breadcrumbs">
        <template #divider>
          <v-icon>$vuetify.icons.mdiChevronRight</v-icon>
        </template>
      </v-breadcrumbs>
    </div>
    <v-row>
      <!-- content -->
      <v-col cols="12" sm="12" md="8" class="pr-6">
        <EvoMarkdown :content="md.default" />
      </v-col>
      <!-- Other News -->
      <v-col cols="12" sm="12" md="4" class="pl-6 evo-opensans-regular">
        <div class="evo-other-news-title evo-text-15 mb-5 text-uppercase">
          {{ $t('news.other_news') }}
        </div>
        <div class="evo-news-wrapper" v-for="(item, index) in items" :key="index">
          <v-divider class="mb-2"></v-divider>
          <router-link :to="item.link" class="router-link">
            <div class="evo-news-title evo-text-13 mb-2" :class="{ active: item.key === id }">
              {{ $t(item.title) }}
            </div>
          </router-link>
          <div class="evo-news-time evo-text-10">{{ item.date }}</div>
        </div>
        <v-divider></v-divider>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { newsroomDetailMixin } from '@/mixins/newsroom.js';
export default {
  name: 'EvoNewsroomDetailSection',
  mixins: [newsroomDetailMixin]
};
</script>
<style lang="scss" scoped>
::v-deep(.evo-breadcrumbs) {
  padding-top: 0;
  padding-left: 0;
  display: inline-block;
  .v-breadcrumbs__item {
    // color: #222a41;
    color: #459eff;
    font-size: 15px;
    font-family: 'OpenSans-Regular';
  }
}
.evo-privacy-policy-wrapper {
  position: relative;
  margin: 36px -54px 0px;

  .evo-image-warpper {
    position: relative;
    background: rgba(255, 255, 255, 0.45);
    .evo-image-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
    }
  }

  .evo-self-updated {
    color: #6d7783;
    line-height: 20px;
  }

  .evo-title {
    color: #222a41;
    line-height: 30px;
  }

  .evo-header {
    color: #222a41;
  }

  .evo-answer {
    color: #222a41;
  }

  .evo-other-news-title {
    color: #222a41;
    font-weight: bold;
    line-height: 23px;
  }

  .evo-news-wrapper {
    margin-bottom: 10px;
    .evo-news-title {
      color: #222a41;
      font-weight: bold;
      line-height: 20px;
      &.active {
        color: #b4b4b4;
      }
    }
    .evo-news-time {
      color: #6d7783;
      line-height: 14px;
    }
  }
}
</style>
