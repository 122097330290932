<template>
  <div class="evo-newsroom-detail-wrapper">
    <template v-if="$vuetify.breakpoint.mobile">
      <EvoMNewsroomDetailSection />
    </template>
    <template v-else>
      <EvoNewsroomDetailSection />
    </template>
  </div>
</template>
<script>
import EvoNewsroomDetailSection from '@/components/newsroom_sections/Detail.vue';
import EvoMNewsroomDetailSection from '@/mobile/components/newsroom_sections/MDetail.vue';
export default {
  name: 'EvoNewsroomDetailView',
  components: {
    EvoNewsroomDetailSection,
    EvoMNewsroomDetailSection
  }
};
</script>
<style lang="scss" scoped>
.evo-newsroom-detail-wrapper {
  position: relative;
  background: $header_bg_color;
}
</style>